import { SnackbarContext } from 'components/ui/snackbar/context/Snackbar'
import React, { useContext, useState } from 'react'
import styles from './styles.module.scss'
import { Col, Container, FormGroup, Input, Label, Modal, ModalBody, Row } from 'reactstrap'
import { Formik, Form } from 'formik'
import { ILabel } from 'Types/ILabel'
import userApi from 'services/userApi'
import { UserVacationRequest } from './UserVacationRequest'

interface IProps {
  isOpen: boolean
  setCreateVacationModal: (open: boolean) => void
  users: ILabel[]
}

const VacationCreateModal: React.FC<IProps> = (props: IProps) => {
  const snackbarContext = useContext(SnackbarContext)
  const [selectedUserId, setSelectedUserId] = useState<number | undefined>()
  const [vacationDays, setVacationDays] = useState<{
    oldVacationDays: number | undefined
    newVacationDays: number | undefined
    promisedVacationDays: number | undefined
  }>()
  const [selectedUser, setSelectedUser] = useState<string | undefined>()
  const [loading, setLoading] = useState<boolean>(false)

  function customHandleChange(e: any, fieldName: string, setFieldValue: any): void {
    if (e) {
      setFieldValue(fieldName, e.target.value)
      switch (fieldName) {
        case 'oldVacationDays': {
          setVacationDays({
            oldVacationDays: e.target.value,
            newVacationDays: vacationDays?.newVacationDays,
            promisedVacationDays: vacationDays?.promisedVacationDays,
          })
          break
        }
        case 'newVacationDays': {
          setVacationDays({
            oldVacationDays: vacationDays?.oldVacationDays,
            newVacationDays: e.target.value,
            promisedVacationDays: vacationDays?.promisedVacationDays,
          })
          break
        }
        case 'promisedVacationDays': {
          setVacationDays({
            oldVacationDays: vacationDays?.oldVacationDays,
            newVacationDays: vacationDays?.newVacationDays,
            promisedVacationDays: e.target.value,
          })
          break
        }
      }
    }
  }

  async function selectUser(userId: number) {
    if (selectedUserId)
      document.getElementById('user-' + selectedUserId)?.classList.remove(`${styles.active}`)
    let selectedUser = props.users.filter((user) => user.value === userId)
    const user = selectedUser[0]
    setSelectedUser(user.label)
    setSelectedUserId(user.value)
    document.getElementById('user-' + userId)?.classList.toggle(`${styles.active}`)

    setLoading(true)
    const response = await userApi
      .getUserVacations(userId)
      .catch((e) => snackbarContext?.setShowSnackbarData(true, e.message, 'danger'))
      .finally(() => setLoading(false))
    setVacationDays(response)
  }

  function updateUserVacationDays(userId: number, userVacationRequest: UserVacationRequest) {
    setLoading(true)
    userApi
      .updateUserVacationDays(userId, userVacationRequest)
      .then(() => {
        snackbarContext?.setShowSnackbarData(true, 'Vacation days updated', 'success')
        props.setCreateVacationModal(false)
      })
      .catch((e) => {
        snackbarContext?.setShowSnackbarData(true, e.message, 'danger')
      })
      .finally(() => {
        setLoading(false)
        setSelectedUser(undefined)
        setSelectedUserId(undefined)
        setVacationDays(undefined)
      })
  }

  return (
    <Modal isOpen={props.isOpen} centered={true} contentClassName="modalContent" size="md">
      <ModalBody>
        <Container>
          <h3 className="modalTitle">Choose user</h3>
          <Formik
            enableReinitialize
            initialValues={{
              oldVacationDays: vacationDays?.oldVacationDays,
              newVacationDays: vacationDays?.newVacationDays,
              promisedVacationDays: vacationDays?.promisedVacationDays,
            }}
            onSubmit={(values: any) => {
              let userVacationRequest: UserVacationRequest = values
              selectedUserId && updateUserVacationDays(selectedUserId, userVacationRequest)
            }}
          >
            {(props2) => {
              const { handleBlur, setFieldValue } = props2
              return (
                <Form>
                  <Row style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <Col>
                      {props.users.map((user) => {
                        return (
                          <button
                            key={user.value}
                            id={`user-${user.value}`}
                            className={styles.vacationButton}
                            onClick={() => selectUser(user.value)}
                            type="button"
                          >
                            {user.label}
                          </button>
                        )
                      })}
                    </Col>
                  </Row>

                  {selectedUser && (
                    <>
                      <div className={styles.divider}></div>

                      <Row>
                        <Col>{selectedUser}</Col>
                      </Row>
                      <FormGroup>
                        <Label className="modalLabel" for="vacationDays">
                          Promised vacation days:
                        </Label>
                        <Input
                          type="number"
                          min={0}
                          value={vacationDays?.promisedVacationDays}
                          placeholder="Enter number of promised vacation days..."
                          name="promisedVacationDays"
                          onBlur={handleBlur}
                          disabled={loading}
                          onChange={(e) =>
                            customHandleChange(e, 'promisedVacationDays', setFieldValue)
                          }
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label className="modalLabel" for="vacationDays">
                          Old vacation days:
                        </Label>
                        <Input
                          type="number"
                          min={0}
                          value={vacationDays?.oldVacationDays}
                          placeholder="Enter number of old vacation days..."
                          name="oldVacationDays"
                          onBlur={handleBlur}
                          disabled={loading}
                          onChange={(e) => customHandleChange(e, 'oldVacationDays', setFieldValue)}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label className="modalLabel" for="vacationDays">
                          New vacation days:
                        </Label>
                        <Input
                          type="number"
                          min={0}
                          value={vacationDays?.newVacationDays}
                          placeholder="Enter number of new vacation days..."
                          name="newVacationDays"
                          onBlur={handleBlur}
                          disabled={loading}
                          onChange={(e) => customHandleChange(e, 'newVacationDays', setFieldValue)}
                        />
                      </FormGroup>
                    </>
                  )}
                  <div style={{ padding: 8 }}></div>
                  <Row className="modalRowCenter">
                    <Col>
                      <button
                        className="cancelButton"
                        type="button"
                        onClick={() => {
                          props.setCreateVacationModal(false)
                          setSelectedUser(undefined)
                          setVacationDays(undefined)
                        }}
                      >
                        Cancel
                      </button>
                    </Col>
                    <Col>
                      <button className="modalAddButton" type="submit" disabled={loading}>
                        {'Update Vacation days'}
                      </button>
                    </Col>
                  </Row>
                </Form>
              )
            }}
          </Formik>
        </Container>
      </ModalBody>
    </Modal>
  )
}

export default VacationCreateModal
